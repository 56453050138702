import React from "react";
import HomeAdvantages from "../components/homeAdvantages";
import HomeHotSlider from "../components/homeHotSlider";
import HomeMainSlider from "../components/homeMainSlider";

export default function Home() {
    return (
        <div id="content">
            <HomeMainSlider/>
            <HomeAdvantages/>
            <HomeHotSlider/>
        </div>
    );
};