import React, {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {RouterProvider} from "react-router-dom";
import {SkeletonTheme} from 'react-loading-skeleton';
import {Provider} from 'react-redux';
import {store} from './app/store';
import reportWebVitals from './reportWebVitals';
import './index.css';
import routesConfig from "./routes/routesConfig";

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <StrictMode>
        <Provider store={store}>
            <SkeletonTheme baseColor="#E8E8E8" highlightColor="#F0F0F0">
                <RouterProvider router={routesConfig}/>
            </SkeletonTheme>
        </Provider>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
