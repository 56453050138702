import React from 'react';
import {Link} from "react-router-dom";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TopBar = () => (
    <div className={"bg-dark fixed-top"}>
        <div className="container">
            <div className="row">
                <div className="col-md-6 d-flex align-items-center mb-md-0">
                    <a href="tel:+1786-260-4808" className="btn btn-sm btn-success me-1 text-decoration-none"
                       style={{fontSize: "0.85rem", padding: "0.05rem 0.25rem"}}>
                        <FontAwesomeIcon icon={faPhone}/> Call Now (786)-260-4808
                    </a>
                    <span className={"text-light"} style={{fontSize: "0.85rem"}}>And reserve your party!</span>
                </div>
                <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-md-end text-md-end">
                    <ul className="list-unstyled d-inline-flex mb-0 gap-4"
                        style={{fontSize: "0.85rem"}}>
                        <li className="me-lg-4">
                            <Link to="/contact">Contact</Link>
                        </li>
                        <li className="px-2">|</li>
                        <li>
                            <Link to="/gallery">Gallery</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
);

export default TopBar;
