import React from 'react';
import {createBrowserRouter, RouteObject} from "react-router-dom";
import App from '../App';
import ErrorPage from "../pages/error";
import AdminHome from "../pages/admin/home";
import Login from "../pages/login";
import NotFound from "../pages/404";
import Gallery from "../pages/gallery";
import Contact from "../pages/contact";
import Basket from "../pages/basket";
import CheckOut from "../pages/checkout";
import Home from "../pages/home";
import Detail from "../pages/detail";
import ProtectedRoute from "./protectedRoute";
import AdminOrders from "../pages/admin/orders";
import Logout from "../pages/logout";

const configRouterObject: RouteObject[] = [
    {
        path: "/",
        element: <App/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "",
                element: <Home/>,
            },
            {
                path: 'login',
                element: <Login/>
            },
            {
                path: 'logout',
                element: <Logout/>
            },
            {
                path: "gallery",
                element: <Gallery/>,
            },
            {
                path: "contact",
                element: <Contact/>,
            },
            {
                path: "basket",
                element: <Basket/>,
            },
            {
                path: "checkout",
                element: <CheckOut/>,
            },
            {
                path: "detail/:id",
                element: <Detail/>,
            },
            {
                path: "admin",
                element: <ProtectedRoute/>,
                children: [
                    {
                        path: '',
                        element: <AdminHome/>
                    },
                    {
                        path: 'orders',
                        element: <AdminOrders/>
                    }
                ]
            },
            {
                path: "*",
                element: <NotFound/>,
            }
        ]
    },
];
const RoutesConfig = createBrowserRouter(configRouterObject);
export default RoutesConfig;