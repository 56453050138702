import React from "react";
import {Row} from "react-bootstrap";
import {Link} from "react-router-dom";

const AdminOrders = () => {
    return (
        <div id="content">
            <Row>
                <div className="col-lg-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={'/admin'}>Admin</Link></li>
                            <li aria-current="page" className="breadcrumb-item active">My orders</li>
                        </ol>
                    </nav>
                </div>
                <div className="col-lg-3">
                    <div className="card sidebar-menu">
                        <div className="card-header">
                            <h3 className="h4 card-title">Admin section</h3>
                        </div>
                        <div className="card-body">
                            <ul className="nav nav-pills flex-column">
                                <Link to={'/admin'} className="nav-link">
                                    <i className="fa fa-list"></i> Dashboard
                                </Link>
                                <Link to={'/admin/orders'} className="nav-link active">
                                    <i className="fa fa-heart"></i> My orders
                                </Link>
                                <Link to={''} className="nav-link">
                                    <i className="fa fa-sign-out"></i> Logout
                                </Link>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="customer-orders" className="col-lg-9">
                    <div className="box">
                        <h1>My orders</h1>
                        <p className="lead">Your orders on one place.</p>
                        <p className="text-muted">If you have any questions, please feel free to <a href="contact.html">contact
                            us</a>, our customer service center is working for you 24/7.</p>
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Date</th>
                                    <th>Total</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th># 1735</th>
                                    <td>22/06/2013</td>
                                    <td>$ 150.00</td>
                                    <td><span className="badge badge-info">Being prepared</span></td>
                                    <td><a href="customer-order.html" className="btn btn-primary btn-sm">View</a></td>
                                </tr>
                                <tr>
                                    <th># 1735</th>
                                    <td>22/06/2013</td>
                                    <td>$ 150.00</td>
                                    <td><span className="badge badge-info">Being prepared</span></td>
                                    <td><a href="customer-order.html" className="btn btn-primary btn-sm">View</a></td>
                                </tr>
                                <tr>
                                    <th># 1735</th>
                                    <td>22/06/2013</td>
                                    <td>$ 150.00</td>
                                    <td><span className="badge badge-success">Received</span></td>
                                    <td><a href="customer-order.html" className="btn btn-primary btn-sm">View</a></td>
                                </tr>
                                <tr>
                                    <th># 1735</th>
                                    <td>22/06/2013</td>
                                    <td>$ 150.00</td>
                                    <td><span className="badge badge-danger">Cancelled</span></td>
                                    <td><a href="customer-order.html" className="btn btn-primary btn-sm">View</a></td>
                                </tr>
                                <tr>
                                    <th># 1735</th>
                                    <td>22/06/2013</td>
                                    <td>$ 150.00</td>
                                    <td><span className="badge badge-warning">On hold</span></td>
                                    <td><a href="customer-order.html" className="btn btn-primary btn-sm">View</a></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Row>
        </div>
    );
}

export default AdminOrders;
