import http from "./http-common";

const headers = () => {
    return {
        headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken')
        }
    }
}

export default class EmailService {

    createEmail(data) {
        return http.post("email", data, headers());
    }

}