import React from "react";
import {Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot, faPhone} from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
    return (
        <div className="container mb-5">
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <div className="p-4 bg-light shadow-sm rounded mb-4">
                        <h1 className="text-primary user-select-none">Contact</h1>
                    </div>
                    <div className="card p-4 bg-light">
                        <p className="lead">Are you curious about something? Do you have some kind of problem with our
                            products?</p>
                        <p>Please feel free to contact us, our customer service center is working for you 24/7.</p>
                        <hr/>
                        <div className="row">
                            <div className="col-md-4">
                                <p className="text-center">
                                    <Image src={process.env.PUBLIC_URL + "/img/contact/fabiam.jpg"} roundedCircle
                                           width={150} height={150}/>
                                </p>
                                <p className="text-center">Fabiam Gonzalez</p>
                            </div>
                            <div className="col-md-4">
                                <p className="text-center">
                                    <Image src={process.env.PUBLIC_URL + "/img/contact/cristiam.jpg"} roundedCircle
                                           width={150} height={150}/>
                                </p>
                                <p className="text-center">Cristiam Gonzalez</p>
                            </div>
                            <div className="col-md-4">
                                <p className="text-center">
                                    <Image src={process.env.PUBLIC_URL + "/img/contact/logo.jpg"} roundedCircle
                                           width={150} height={150}/>
                                </p>
                                <p className="text-center">Tropical Rental Party</p>
                            </div>
                        </div>
                        <hr/>
                        <div className="row">
                            <div className="col-md-4">
                                <h3>
                                    <FontAwesomeIcon icon={faLocationDot}/> Location
                                </h3>
                                <p>
                                    Homestead
                                    <br/>
                                    <strong>Florida</strong>
                                </p>
                            </div>
                            <div className="col-md-4">
                                <h3>
                                    <FontAwesomeIcon icon={faPhone}/> Service phone
                                </h3>
                                <p className="text-muted">This number is toll free.</p>
                                <p>
                                    <strong>+1 786 260 4808</strong>
                                    <br/>
                                    <strong>+1 305 607 7291</strong>
                                </p>
                            </div>
                            <div className="col-md-4">
                                <h3>
                                    <FontAwesomeIcon icon={faPhone}/> Electronic support
                                </h3>
                                <p className="text-muted">Please feel free to write an email to us.</p>
                                <strong>
                                    <a href="mailto:tropicalpartyrentalinc@gmail.com">tropicalpartyrentalinc@gmail.com</a>
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
