import {Navigate, Outlet} from "react-router-dom";

export default function ProtectedRoute() {
    const session = localStorage.getItem("accessToken");
    if (session === null) {
        return <Navigate to={'/login'} replace/>
    }
    return <Outlet/>;
};

