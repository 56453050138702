import React, { useEffect, useState } from "react";
import GalleryList from "../components/galleryList";
import ItemService from "../services/item.service";
import { Spinner } from "react-bootstrap";

const Gallery = (props) => {
    const itemService = new ItemService();
    const [items, setItems] = useState([]);

    useEffect(() => {
        itemService.getAllActives().then(response => {
            setItems(response.data);
        });
    }, []);

    return (
        <div className="container mb-5">
            <div className="row">
                {/* <SideBar filterItems={this.filterItems} /> */}
                <div className="col-lg-12">
                    <div className="p-4 bg-light shadow-sm rounded mb-4">
                        <h1 className="text-primary user-select-none">Gallery</h1>
                    </div>
                    {items.length > 0 ? (
                        <GalleryList items={items} history={props.history} />
                    ) : (
                        <div className="d-flex justify-content-center">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    )}
                    {/* <Pages /> */}
                </div>
            </div>
        </div>
    );
};

export default Gallery;
