import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faTags, faThumbsUp } from "@fortawesome/free-solid-svg-icons";

const HomeAdvantages = () => (
    <>
        <div className="container py-3">
            <div className="row">
                <div className="col-md-12">
                    <h3 className="text-primary text-uppercase">From our gallery</h3>
                    <p className="lead mb-0">Explore what you can find in our gallery for rent! <Link to={'/gallery'}>Check it out!</Link></p>
                </div>
            </div>
        </div>
        <div id="advantages" className="container">
            <div className="row mb-4 user-select-none">
                <div className="col-md-4 col-sm-6 mb-4 mb-sm-0">
                    <div className="box d-flex flex-column justify-content-center mb-0 h-100">
                        <div className="icon"><FontAwesomeIcon icon={faHeart} /></div>
                        <h4 className="text-primary">Customer Satisfaction</h4>
                        <p className="mb-0">We prioritize providing the best service to ensure our customers are satisfied.</p>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 mb-4 mb-sm-0">
                    <div className="box d-flex flex-column justify-content-center mb-0 h-100">
                        <div className="icon"><FontAwesomeIcon icon={faTags} /></div>
                        <h4 className="text-primary">Affordable Prices</h4>
                        <p className="mb-0">Explore our competitive pricing options for a range of products.</p>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className="box d-flex flex-column justify-content-center mb-0 h-100">
                        <div className="icon"><FontAwesomeIcon icon={faThumbsUp} /></div>
                        <h4 className="text-primary">Satisfaction Guarantee</h4>
                        <p className="mb-0">Experience our commitment to 100% satisfaction and enjoy excellent quality in all products.</p>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default HomeAdvantages;
