//Authentication Service file. This service uses Axios for HTTP requests and Local Storage for user information & JWT.
// It provides following important functions:

// register(): POST {username, email, password}
// login(): POST {username, password} & save JWT to Local Storage
// logout(): remove JWT from Local Storage

import http from "./http-common";

const register = (username, email, password) => {
    return http.post("/auth/signup", {
        username,
        email,
        password,
    });
};
const login = async (username, password) => {
    const response = await http
        .post("/auth/signin", {
            username,
            password,
        });
    if (response.data.accessToken) {
        localStorage.setItem("accessToken", response.data["accessToken"]);
    }
    return response.data;
};
const logout = () => {
    localStorage.removeItem('accessToken');
};
const authService = {
    register,
    login,
    logout,
};
export default authService;