const urlImgSliderMain = process.env.PUBLIC_URL + "/img/slider/";

export const imgSliderMain = [
    {id: "10", realId: 37, src: urlImgSliderMain + "10.webp", title: "Obstacle Course Challenge", description: "bar"},
    {id: "6", realId: 29, src: urlImgSliderMain + "6.webp", title: "Water Slide Tropical", description: "bar"},
    {id: "8", realId: 30, src: urlImgSliderMain + "8.webp", title: "Water Slide Dinosaur Adventure", description: "bar"},
    {id: "9", realId: 32, src: urlImgSliderMain + "9.webp", title: "Water Slide Green Dolphin", description: "bar"},
    {id: "7", realId: 28, src: urlImgSliderMain + "7.webp", title: "Water Slide Summer", description: "Available very soon..."},
    {id: "5", realId: 11, src: urlImgSliderMain + "5.webp", title: "Bounce House Slide Unicorn", description: "Available very soon..."},
    {id: "1", realId: 8, src: urlImgSliderMain + "1.webp", title: "Bounce House SpiderMan", description: "Available very soon..."},
    {id: "2", realId: 13, src: urlImgSliderMain + "2.webp", title: "Water Slide Green", description: "Available very soon..."},
    // { id: "3", src: urlImgSliderMain + "3.webp", title: "Water Slide Yellow", description: "Available very soon..." },
    // { id: "4", src: urlImgSliderMain + "4.webp", title: "Bounce House + Water Slide Blue", description: "Available very soon..." },
];
const categoryArray = [
    {
        id: 1,
        name: 'Bounce House',
        folder: process.env.PUBLIC_URL + "/img/bounce_house/"
    },
    {
        id: 2,
        name: 'Water Slide',
        folder: process.env.PUBLIC_URL + "/img/water_slide/"
    },
    {
        id: 3,
        name: 'Bounce House Slide',
        folder: process.env.PUBLIC_URL + "/img/bounce_house_slide/"
    },
    {
        id: 4,
        name: 'Combo',
        folder: process.env.PUBLIC_URL + "/img/combo/"
    },
    {
        id: 5,
        name: 'Other',
        folder: process.env.PUBLIC_URL + "/img/other/"
    }
]
export const list = [
    {
        id: "37",
        title: "Obstacle Course",
        description: 'Dimensions: 40" x 10" x 13"',
        category: categoryArray[1],
        color: "red",
        available: 1,
        price: 335,
        new: true,
        data: 5,
    },
    {
        id: "30",
        title: "Water Doble Slides Prehistory",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[1],
        color: "green",
        available: 1,
        price: 255,
        new: true,
        data: 2,
    },
    {
        id: "31",
        title: "Water Doble Slides Multicolor",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[1],
        color: "blue",
        available: 1,
        price: 255,
        new: true,
        data: 3,
    },
    {
        id: "32",
        title: "Water Doble Slides Summer",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[1],
        color: "blue",
        available: 1,
        price: 255,
        new: true,
        data: 2,
    },
    {
        id: "33",
        title: "Bounce House Cocomelo",
        description: 'Dimensions: 13" x 13" x 15"',
        category: categoryArray[0],
        color: "green",
        available: 1,
        price: 185,
        new: true,
        data: 1,
    },
    {
        id: "38",
        title: "Bounce House Multicolor",
        description: 'Dimensions: 13" x 13" x 15"',
        category: categoryArray[0],
        color: "green",
        available: 1,
        price: 165,
        new: true,
        data: 2,
    },
    {
        id: "39",
        title: "Bounce House Avengers",
        description: 'Dimensions: 15" x 15" x 15"',
        category: categoryArray[0],
        color: "blue",
        available: 1,
        price: 200,
        new: true,
        data: 3,
    },
    {
        id: "34",
        title: "Water Slide Jungle",
        description: 'Dimensions: 15" x 30" x 20"',
        category: categoryArray[1],
        color: "green",
        available: 1,
        price: 285,
        new: true,
        data: 3,
    },
    {
        id: "35",
        title: "Bounce House Disney Princess",
        description: 'Dimensions: 13" x 13" x 15"',
        category: categoryArray[0],
        color: "pink",
        available: 1,
        price: 175,
        new: true,
        data: 2,
    },
    {
        id: "36",
        title: "Bounce House Slide Blue",
        description: 'Dimensions: 13" x 25" x 15"',
        category: categoryArray[2],
        color: "blue",
        available: 1,
        price: 220,
        new: true,
        data: 2,
    },
    {
        id: "28",
        title: "Water Slide Summer",
        description: 'Dimensions: 15" x 30" x 20"',
        category: categoryArray[1],
        color: "blue",
        available: 1,
        price: 285,
        data: 3,
    },
    {
        id: "29",
        title: "Water Slide Tropical",
        description: 'Dimensions: 15" x 30" x 20"',
        category: categoryArray[1],
        color: "red",
        available: 1,
        price: 285,
        data: 4,
    },
    {
        id: "21",
        title: "SpiderMan BH + Sugar & Pop Machines",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[3],
        color: "red",
        available: 1,
        price: 255,
        del_price: 280,
        data: 3,
    },
    {
        id: "22",
        title: "Blue BH + Sugar & Pop Machines",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[3],
        color: "red",
        available: 1,
        price: 220,
        del_price: 245,
        data: 3,
    },
    {
        id: "11",
        title: "Bounce House Slide Unicorn",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[2],
        color: "blue",
        available: 1,
        price: 250,
        data: 2,
    },
    {
        id: "1",
        title: "Bounce House Paw Patrol",
        description: 'Dimensions: 13" x 13" x 15"',
        category: categoryArray[0],
        color: "blue",
        available: 1,
        price: 175,
        data: 1,
    },
    {
        id: "2",
        title: "Bounce House PJMASKS",
        description: 'Dimensions: 13" x 13" x 15"',
        category: categoryArray[0],
        color: "red",
        available: 1,
        price: "175",
        data: 1,
    },
    {
        id: "3",
        title: "Bounce House Moana",
        description: 'Dimensions: 13" x 13" x 15"',
        category: categoryArray[0],
        color: "blue",
        available: 1,
        price: 175,
        data: 1,
    },
    {
        id: "4",
        title: "Bounce House MultiColor",
        description: 'Dimensions: 13" x 13" x 15"',
        category: categoryArray[0],
        color: "blue",
        available: 2,
        price: 140,
        data: 3,
    },
    {
        id: "5",
        title: "Bounce House Red",
        description: 'Dimensions: 13" x 13" x 15"',
        category: categoryArray[0],
        color: "red",
        available: 1,
        price: 140,
        data: 2,
    },
    {
        id: "6",
        title: "Bounce House Pink",
        description: 'Dimensions: 13" x 13" x 15"',
        category: categoryArray[0],
        color: "pink",
        available: 1,
        price: 140,
        data: 1,
    },
    {
        id: "7",
        title: "Bounce House Blue",
        description: 'Dimensions: 13" x 13" x 15"',
        category: categoryArray[0],
        color: "blue",
        available: 1,
        price: 140,
        data: 3,
    },
    {
        id: "8",
        title: "Bounce House SpiderMan",
        description: 'Dimensions: 13" x 13" x 15"',
        category: categoryArray[0],
        color: "blue",
        available: 2,
        price: 175,
        data: 1,
    },
    {
        id: "9",
        title: "Bounce House Slide Red",
        description: 'Dimensions: 13" x 30" x 15"',
        category: categoryArray[2],
        color: "red",
        available: 1,
        price: 220,
        data: 3,
    },
    {
        id: "10",
        title: "Bounce House Slide Green",
        description: 'Dimensions: 13" x 26" x 15"',
        category: categoryArray[2],
        color: "green",
        available: 2,
        price: 185,
        data: 3,
    },
    {
        id: "12",
        title: "Water Slide Blue",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[1],
        color: "blue",
        available: 1,
        price: 260,
        data: 2,
    },
    {
        id: "13",
        title: "Water Slide Green",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[1],
        color: "green",
        available: 1,
        price: 320,
        data: 2,
    },
    {
        id: "14",
        title: "Water Slide Yellow",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[1],
        color: "yellow",
        available: 1,
        price: 285,
        data: 2,
    },
    {
        id: "15",
        title: "Tent 20x20",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[4],
        color: "white",
        available: 1,
        price: 170,
        data: 4,
    },
    {
        id: "16",
        title: "Tent 10x20",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[4],
        color: "white",
        available: 1,
        price: 120,
        data: 4,
    },
    {
        id: "17",
        title: "Table",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[4],
        color: "white",
        available: 20,
        price: 10,
        data: 3,
    },
    {
        id: "18",
        title: "Chair",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[4],
        color: "white",
        available: 100,
        price: 1.50,
        data: 4,
    },
    {
        id: "19",
        title: "Cotton Candy Machine",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[4],
        color: "blue",
        available: 1,
        price: 45,
        data: 1,
    },
    {
        id: "20",
        title: "Pop Corn Machine",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[4],
        color: "red",
        available: 1,
        price: 45,
        data: 1,
    },
    {
        id: "26",
        title: "Cocktail Wood Table 30in",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[4],
        color: "red",
        available: 1,
        price: 10,
        data: 0,
    },
    {
        id: "27",
        title: "Bar Table",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[4],
        color: "red",
        available: 1,
        price: 10,
        data: 0,
    },
    {
        id: "23",
        title: "Wood Round Tables 60in",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[4],
        color: "red",
        available: 1,
        price: 10,
        data: 0,
    },
    {
        id: "24",
        title: "Plastic Round Table 60in",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[4],
        color: "red",
        available: 1,
        price: 10,
        data: 0,
    },
    {
        id: "25",
        title: "Resin Folding Chair",
        description: 'Dimensions: Available very soon...',
        category: categoryArray[4],
        color: "red",
        available: 1,
        price: 3,
        data: 0,
    }
];