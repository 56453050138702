import React from "react";
import Header from "./components/header";
import {Outlet} from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./css/style.default.css";
import './App.css';
import Footer from "./components/footer";
import 'react-loading-skeleton/dist/skeleton.css'

export default function App() {
    localStorage.setItem("basketItems", JSON.stringify([]));
    localStorage.setItem("basketOrder", JSON.stringify({}));

    return (
        <div className="App d-flex flex-column" style={{ minHeight: '100vh' }}>
            <Header/>
            <Outlet/>
            <Footer/>
        </div>
    );
};
