import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {Image, Button} from "react-bootstrap";
import {faCartShopping} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAppDispatch} from "../app/hooks";
import {increment} from "../features/counter/counterSlice";
import {IItem} from "../models/item";
import {useSelector} from "react-redux";
import {RootState} from "../app/store";

interface GalleryListProps {
    items: Array<IItem>;
}

export default function GalleryList({items}: GalleryListProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {isLoggedIn} = useSelector((state: RootState) => state.auth);

    const addBasketItem = (newItem: IItem) => {
        let basketItems = JSON.parse(localStorage.getItem("basketItems") || "[]");
        let itemFound = basketItems.find((item: IItem) => item.id === newItem.id);
        if (itemFound) {
            itemFound.quantity++;
        } else {
            newItem["quantity"] = 1;
            basketItems.push(newItem);
        }
        localStorage.setItem("basketItems", JSON.stringify(basketItems));
        dispatch(increment());
        navigate("/basket");
    };

    return (
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
            {items.length &&
                items.map((item, index) => {
                    return (
                        <div className="col">
                            <div key={index} className="col card h-100">
                                <div className="card-header">
                                    <div className="h4">{item.title}</div>
                                </div>
                                <div className="card-body bg-light position-relative">
                                    {item.status && ( // Check if status is 'new'
                                        <div
                                            className="badge bg-primary position-absolute top-0 start-0 m-3 fs-6">New</div>
                                        // <div className="badge bg-warning position-absolute top-0 start-0 m-3 fs-6 rounded-pill spinner-grow w-25"
                                        // style={{"animationDuration": '2s'}}>New</div>
                                    )}
                                    <Link to={"/detail/" + item.id} className="stretched-link">
                                        <Image
                                            src={process.env.PUBLIC_URL + '/img' + item.category.folder + '/' + item.id + '/' + item.id + '.webp'}
                                            alt={item.title} className="card-img-top" thumbnail fluid rounded/>
                                    </Link>
                                    <p className="card-text">
                                        {item.promotion ? (
                                            <del className="text-muted">${item.price}</del>
                                        ) : (
                                            <span>${item.price}</span>
                                        )}
                                        {" "}
                                        {item.promotion &&
                                            <span className="badge bg-danger">-${item.price - item.promotion}</span>}
                                    </p>
                                </div>
                                <div className="card-footer bg-light-subtle">
                                    <div className="d-grid gap-2">
                                        <Link to={"/detail/" + item.id}
                                              className="btn btn-outline-secondary text-decoration-none" role="button">
                                            View Detail
                                        </Link>
                                        {isLoggedIn && (
                                            <Button onClick={() => addBasketItem(item)} variant="primary">
                                                <FontAwesomeIcon icon={faCartShopping}/> Add to Cart
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
}
