import React from "react";

const CheckoutOrderSummaryBar = (props) => {
    const shipping = props.shipping ?? '~20',
        total = props.shipping ? props.subTotal + props.shipping : '~' + (props.subTotal + 20);
    return (
        <div className="col-lg-3">
            <div id="order-summary" className="box">
                <div className="box-header">
                    <h3 className="mb-0">Order summary</h3>
                </div>
                <p className="text-muted">Shipping and additional costs are calculated based on the values you have
                    entered.</p>
                <div className="table-responsive">
                    <table className="table">
                        <tbody>
                        <tr>
                            <td>Order subtotal</td>
                            <th>{"$" + props.subTotal}</th>
                        </tr>
                        <tr>
                            <td>Shipping (by Zip Code)</td>
                            <th>{"$" + shipping}</th>
                        </tr>
                        <tr>
                            <td>Tax</td>
                            <th>$0</th>
                        </tr>
                        <tr className="total">
                            <td>Total</td>
                            <th>{"$" + total}</th>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <div className="box">
          <div className="box-header">
            <h4 className="mb-0">Coupon code</h4>
          </div>
          <p className="text-muted">If you have a coupon code, please enter it in the box below.</p>
          <form>
            <div className="input-group">
              <input type="text" className="form-control" />
              <span className="input-group-append">
                <button type="button" className="btn btn-primary">
                  <i className="fa fa-gift"></i>
                </button>
              </span>
            </div>
          </form>
        </div>*/}
        </div>
    );
}

export default CheckoutOrderSummaryBar;
