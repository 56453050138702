import React from "react";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";

const AdminHome = () => {
    return (
        <div id="content">
            <div className="col-lg-3">
                <div className="card sidebar-menu">
                    <div className="card-header">
                        <h3 className="h4 card-title">Admin section</h3>
                    </div>
                    <div className="card-body">
                        <ul className="nav nav-pills flex-column">
                            <Link to={'/admin'} className="nav-link active">
                                <i className="fa fa-list"></i> Dashboard
                            </Link>
                            <Link to={'/admin/orders'} className="nav-link">
                                <i className="fa fa-heart"></i> My orders
                            </Link>
                            <Link to={'/logout'} className="nav-link">
                                <i className="fa fa-sign-out"></i> Logout
                            </Link>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminHome;
