import React, {useEffect, useState} from "react";
import OwlCarousel from "react-owl-carousel";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Breadcrumb, Button, Image, Spinner} from "react-bootstrap";
import ItemService from "../services/item.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartShopping} from "@fortawesome/free-solid-svg-icons";
import SocialLinks from "../components/socialLinks";
import {useAppDispatch} from "../app/hooks";
import {increment} from "../features/counter/counterSlice";
import {IItem} from "../models/item";
import {useSelector} from "react-redux";
import {RootState} from "../app/store";

const Detail = () => {
    const dispatch = useAppDispatch();
    const {isLoggedIn} = useSelector((state: RootState) => state.auth);
    const itemService = new ItemService();
    const {id} = useParams();
    const [item, setItem] = useState<IItem>();
    const navigate = useNavigate();

    const addBasketItem = (newItem: IItem) => {
        let basketItems = JSON.parse(localStorage.getItem("basketItems") || "[]");
        let itemFound = basketItems.find((item: IItem) => item.id === newItem.id);
        if (itemFound) {
            itemFound.quantity++;
        } else {
            newItem.quantity = 1;
            basketItems.push(newItem);
        }
        localStorage.setItem("basketItems", JSON.stringify(basketItems));
        dispatch(increment());
        navigate("/basket");
    };

    useEffect(() => {
        itemService.get(id).then(response => {
            setItem(response.data as IItem);
        });
    }, [id]);

    return (
        <div id="content">
            <div className="container">
                <Breadcrumb className="mb-3">
                    <Breadcrumb.Item><Link to="/gallery">Gallery</Link></Breadcrumb.Item>
                    <Breadcrumb.Item active>Detail</Breadcrumb.Item>
                </Breadcrumb>
                <div className="row">
                    {item ? (
                        <div className="col-lg-12 order-1 order-lg-2">
                            <div id="productMain" className="row">
                                <div className="col-md-6">
                                    <Image
                                        src={`${process.env.PUBLIC_URL}/img${item.category.folder}/${item.id}/${item.id}.webp`}
                                        thumbnail
                                        alt={item.title}
                                    />
                                </div>
                                <div className="col-md-6 d-flex flex-column">
                                    <div className="card flex-grow-1">
                                        <div className="card-body">
                                            <h1 className="card-title text-center text-primary">{item.title}</h1>
                                            <ul className="list-unstyled">
                                                <li>{item.dimensions}</li>
                                            </ul>
                                            <p className="card-text price ">{"$" + item.price}</p>
                                            <div className="text-center">
                                                {isLoggedIn && (
                                                    <Button variant="primary" onClick={() => addBasketItem(item)}>
                                                        <FontAwesomeIcon icon={faCartShopping}/> Add to cart
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <OwlCarousel items={3} loop margin={5} autoplay className="owl-theme mt-4">
                                        {item.data !== 0 ? (
                                            Array.from({length: item.data}, (_, i) => (
                                                <Image
                                                    key={i}
                                                    src={`${process.env.PUBLIC_URL}/img${item.category.folder}/${item.id}/${item.id}_${i + 1}.webp`}
                                                    thumbnail
                                                />
                                            ))
                                        ) : (
                                            <Image src={`${process.env.PUBLIC_URL}/img/empty/empty_1x1.jpg`} thumbnail/>
                                        )}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Detail;
