import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logout} from "../slices/auth.slide";
import {Spinner} from "react-bootstrap";

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        // @ts-ignore
        dispatch(logout())
            .then(() => {
                navigate("/admin", {replace: true});
            });

    }, [dispatch, navigate]);
    return (
        <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
    );
}

export default Logout;
