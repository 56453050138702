import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { imgSliderMain } from "../data";

const HomeMainSlider = () => {
    return (
        <div className="carousel slide mx-5" data-bs-ride="carousel" data-pause={"false"}
             data-bs-interval={"5000"} >
            <div className="carousel-inner">
                {imgSliderMain.map((item, index) => (
                    <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}>
                        <Link to={`/detail/${item.realId}`} className="d-block">
                            <Image src={item.src} title={item.title} alt={item.description} rounded fluid />
                        </Link>
                    </div>
                ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#homeMainSlider" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#homeMainSlider" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
            <div className="carousel-indicators">
                {imgSliderMain.map((item, index) => (
                    <button type={"button"} key={index} data-bs-target="#homeMainSlider" data-bs-slide-to={index} className={index === 0 ? "active" : ""}></button>
                ))}
            </div>
        </div>
    );
}

export default HomeMainSlider;
