import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Button, Col, Form, Row} from "react-bootstrap";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CheckoutAddress = (props) => {
    const order = JSON.parse(localStorage.getItem("basketOrder"));
    const [validated, setValidated] = useState(false);
    const [obj, setObj] = useState({
        ...order,
        firstName: !Object.hasOwn(order, 'firstName') ? '' : order.firstName,
        lastName: !Object.hasOwn(order, 'lastName') ? '' : order.lastName,
        address: !Object.hasOwn(order, 'address') ? '' : order.address,
        zipCode: !Object.hasOwn(order, 'zipCode') ? '' : order.zipCode,
        phone: !Object.hasOwn(order, 'phone') ? '' : order.phone,
        email: !Object.hasOwn(order, 'email') ? '' : order.email,
        city: !Object.hasOwn(order, 'city') ? '' : order.city,
        shipping: !Object.hasOwn(order, 'shipping') ? 20 : order.shipping,
    })

    const handleClickNext = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            localStorage.setItem("basketOrder", JSON.stringify(obj));
            props.changeUrl("delivery");
        }
    };

    const handleChange = (e) => {
        let value = e.target.value;
        const id = e.target.id;
        if (id === 'zipCode') {
            let shipping = 0;
            switch (parseInt(value)) {
                case 33024:
                    shipping = 30;
                    break;
                case 33032:
                    shipping = 10;
                    break;
                default:
                    shipping = 20;
                    break;
            }
            setObj({...obj, [id]: parseInt(value), shipping: shipping})
            props.setShipping(shipping);
        } else {
            setObj({...obj, [id]: value})
        }
    };

    return (
        <div className="content py-3">
            <Form noValidate validated={validated} onSubmit={handleClickNext}>
                <Row>
                    <Form.Group controlId="firstName" as={Col} md="6">
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control type="text" value={obj.firstName} placeholder="First Name"
                                      onChange={handleChange} required/>
                        <Form.Control.Feedback type="invalid">
                            Please provide a First Name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="lastName" as={Col} md={"6"}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" value={obj.lastName} placeholder="Last Name"
                                      onChange={handleChange}/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group controlId="address" as={Col} md="6">
                        <Form.Label>CheckoutAddress*</Form.Label>
                        <Form.Control type="text" value={obj.address} placeholder="CheckoutAddress"
                                      onChange={handleChange} required/>
                        <Form.Control.Feedback type="invalid">
                            Please provide a CheckoutAddress.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="city" as={Col} md="3">
                        <Form.Label>City*</Form.Label>
                        <Form.Control type="text" value={obj.city} placeholder="City"
                                      onChange={handleChange} required/>
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid city.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="zipCode" as={Col} md="3">
                        <Form.Label>Zip Code*</Form.Label>
                        <Form.Control type="number" value={obj.zipCode} placeholder="Zip Code"
                                      onChange={handleChange} required/>
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid Zip Code.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group controlId="phone" as={Col} md="6">
                        <Form.Label>Phone*</Form.Label>
                        <Form.Control type="text" value={obj.phone} placeholder="Phone"
                                      onChange={handleChange} required/>
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid phone number.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="email" as={Col} md="6">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" value={obj.email} placeholder="Email"
                                      onChange={handleChange}/>
                    </Form.Group>
                </Row>
                <div className="box-footer d-flex justify-content-between">
                    <Link to="/basket" className="btn btn-outline-secondary">
                        <FontAwesomeIcon icon={faChevronLeft} />Back to Basket
                    </Link>
                    <Button type="submit" variant="primary">
                        Continue to Delivery Method<FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                </div>
            </Form>
        </div>
    );
}

export default CheckoutAddress;
