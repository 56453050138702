import http from "./http-common";

const options = {
    headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken')
    }
};

export default class ItemService {
    getAll() {
        return http.get("item");
    }

    getAllActives() {
        return http.get("item/active");
    }

    getTopView() {
        return http.get("item/top_view");
    }

    get(id) {
        return http.get(`item/${id}`);
    }

    create(data) {
        return http.post("item", data, options);
    }

    update(id, data) {
        return http.put(`item/${id}`, data, options);
    }

    delete(id) {
        return http.delete(`item/${id}`, options);
    }

    deleteAll() {
        return http.delete(`item`, options);
    }

    findByTitle(title) {
        return http.get(`item?title=${title}`);
    }
}