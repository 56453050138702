import React, {useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CheckoutDelivery = (props) => {
    const order = JSON.parse(localStorage.getItem("basketOrder"));
    const [validated, setValidated] = useState(false);
    const defaultDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate() + 1).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [obj, setObj] = useState({
        ...order,
        additionalInfo: order.additionalInfo === undefined ? '' : order.additionalInfo,
        date: order.date === undefined ? defaultDate() : order.date,
    })

    const handleClickNext = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            localStorage.setItem("basketOrder", JSON.stringify(obj));
            props.changeUrl("payment");
        }
    };

    const handleClickPrevious = () => {
        props.changeUrl("address");
    };

    const handleChange = (e) => {
        setObj({...obj, [e.target.id]: e.target.value})
    };

    return (
        <div className="content py-3">
            <Form noValidate validated={validated} onSubmit={handleClickNext}>
                <Row>
                    <Form.Group controlId="date" as={Col} md="4">
                        <Form.Label>Select Date*</Form.Label>
                        <Form.Control type="date" value={obj.date} min={defaultDate()} placeholder="Date of CheckoutDelivery"
                                      onChange={handleChange} required/>
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid Date.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="7">
                        <Form.Label>*All deliveries are planned to be delivered in the morning and the day before you
                            will
                            receive a call with a confirmation and an estimated delivery time.</Form.Label>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group controlId="additionalInfo" as={Col} md="12">
                        <Form.Label>Additional Instructions</Form.Label>
                        <Form.Control type="input" as={"textarea"} value={obj.additionalInfo}
                                      placeholder="Addicional instructions"
                                      onChange={handleChange} rows="3"/>
                    </Form.Group>
                </Row>
                <div className="box-footer d-flex justify-content-between">
                    {/*<Button onClick={handleClickPrevious} className="btn btn-outline-secondary">*/}
                    {/*    <i className="fa fa-chevron-left"></i>Back to CheckoutAddress*/}
                    {/*</Button>*/}
                    <button onClick={handleClickPrevious} className="btn btn-outline-secondary">
                        <FontAwesomeIcon icon={faChevronLeft} />Back to Address
                    </button>
                    <Button type="submit" className="btn btn-primary">
                        Continue to Payment Method<FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                </div>
            </Form>
        </div>
    );
}

export default CheckoutDelivery;
