import React, {useState} from "react";
import {Button, Image, Modal, ProgressBar, Spinner} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import OrderService from "../services/order.service"
import OrderItemService from "../services/orderItem.service"
import EventService from "../services/event.service"
import EmailService from "../services/email.service";
import AppService from "../services/app.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";


const CheckoutReview = (props) => {
    const orderService = new OrderService();
    const orderItemService = new OrderItemService();
    const eventService = new EventService();
    const emailService = new EmailService();
    const appService = new AppService()
    const [show, setShow] = useState(false)
    const navigate = useNavigate();
    const items = JSON.parse(localStorage.getItem("basketItems"));
    const order = JSON.parse(localStorage.getItem("basketOrder"));
    order.totalItems = items.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.quantity;
    }, 0);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [orderNumber, setOrderNumber] = useState(false);

    const generateOrderItems = async (orderId) => {
        for (const item of items) {
            await orderItemService.create({
                'order_id': orderId,
                'item_id': parseInt(item.id),
                'quantity': item.quantity
            });
        }
    }

    const handleClickNext = (event) => {
        event.currentTarget.disabled = true;
        setLoading(true);
        setShow(true);
        appService.getToken().then((response) => {
            sessionStorage.setItem('accessToken', response.data.accessToken)
            orderService.create(order).then(async response => {
                setProgress(25)
                const orderId = response.data.id;
                order.id = orderId;
                generateOrderItems(orderId).then(async () => {
                    setProgress(50)
                    await eventService.create({'order_id': orderId}).then(() => {
                        setProgress(75)
                        setOrderNumber(orderId);
                        localStorage.setItem("basketItems", JSON.stringify([]));
                        localStorage.setItem("basketOrder", JSON.stringify({}));
                        window.dispatchEvent(new Event('storage'))
                        emailService.createEmail({'order_id': orderId, 'template_id': 4}).then(() => {
                            emailService.createEmail({'order_id': orderId, 'template_id': 2}).then(() => {
                                setProgress(100)
                                setLoading(false);
                            });
                        });
                    })
                })
            }).catch(e => {
                console.log(e);
            });
        })
    };

    const handleClickPrevious = () => {
        props.changeUrl("payment");
    };

    const handleClickModalClose = () => {
        navigate("/gallery");
    };

    const renderLoadingText = () => {
        switch (progress) {
            case 0:
                return "Creating order ...";
            case 25:
                return "Collecting order information...";
            case 50:
                return "Contacting services operators...";
            case 75:
                return "Sending a email with order details...";
            case 100:
                return "Order successfully created...";
            default:
                return "";
        }
    };

    return <>
        <Modal show={show} onHide={() => setShow(!show)} centered>
            <Modal.Header>
                <Modal.Title>New Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderLoadingText()}
                {loading ? <ProgressBar animated now={progress} variant={"success"}/>
                    : (<>Woohoo, your order was successfully created!<br/>Order #{orderNumber}</>)}
            </Modal.Body>
            <Modal.Footer>
                {loading ? <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    variant={"success"}
                /> : <Button variant="primary" onClick={handleClickModalClose}>Ok</Button>}
            </Modal.Footer>
        </Modal>
        <div className="table-responsive">
            <table className="table">
                <thead>
                <tr>
                    <th colSpan="2">Product</th>
                    <th>Quantity</th>
                    <th>Unit price</th>
                    <th>Discount</th>
                    <th>Total(u)</th>
                </tr>
                </thead>
                <tbody>
                {items.map((item, index) => {
                    return <tr key={index}>
                        <td>
                            <Link to={"/detail/" + item.id}>
                                <Image
                                    src={process.env.PUBLIC_URL + '/img' + item.category.folder + '/' + item.id + '/' + item.id + '.webp'}
                                    thumbnail fluid/>
                            </Link>
                        </td>
                        <td>
                            <Link to={"/detail/" + item.id}>
                                {item.title}
                            </Link>
                        </td>
                        <td>{item.quantity}</td>
                        <td>{"$" + item.price}</td>
                        <td>$0.00</td>
                        <td>{"$" + item.price * item.quantity}</td>
                    </tr>;
                })}
                </tbody>
                <tfoot>
                <tr>
                    <th colSpan="5">Sub Total</th>
                    <th colSpan="2">{"$" + (order.subTotal + order.shipping).toString()}</th>
                </tr>
                </tfoot>
            </table>
        </div>
        <div className="box-footer d-flex justify-content-between">
            <button onClick={handleClickPrevious} className="btn btn-outline-secondary">
                <FontAwesomeIcon icon={faChevronLeft}/>Back to Payment Method
            </button>
            <button onClick={handleClickNext} className="btn btn-primary">
                Place an order <FontAwesomeIcon icon={faChevronRight}/>
            </button>
        </div>
    </>;
}

export default CheckoutReview;
