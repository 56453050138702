import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faYelp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

interface SocialLinksProps {
    iconSize?: number;
}

const SocialLinks: React.FC<SocialLinksProps> = ({ iconSize = 15 }) => (
    <div className="d-flex align-items-center justify-content-center">
        <a href="https://www.facebook.com/tropicalpartyrental" target="_blank" rel="noopener noreferrer"
           className="external facebook me-3">
            <FontAwesomeIcon icon={faFacebookF} fontSize={iconSize + "px"} />
        </a>
        <a href="https://www.instagram.com/tropicalpartyrental" target="_blank" rel="noopener noreferrer"
           className="external instagram me-3">
            <FontAwesomeIcon icon={faInstagram} fontSize={iconSize} />
        </a>
        <a href="https://www.yelp.com/biz/tropical-party-rental-homestead" target="_blank" rel="noopener noreferrer"
           className="external yelp me-3">
            <FontAwesomeIcon icon={faYelp} fontSize={iconSize} />
        </a>
        <a href="mailto:tropicalpartyrentalinc@gmail.com" className="email">
            <FontAwesomeIcon icon={faEnvelope} fontSize={iconSize} />
        </a>
    </div>
);

export default SocialLinks;
