import React from 'react';
import {Link, NavLink} from "react-router-dom";
import {Image} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCartShopping} from "@fortawesome/free-solid-svg-icons";
import SocialLinks from "./socialLinks";
import {Navbar, Container, Nav} from "react-bootstrap";
import {useAppSelector} from "../app/hooks";
import {selectCount} from "../features/counter/counterSlice";
import {useSelector} from "react-redux";
import {RootState} from "../app/store";

const NavBar = () => {
    const count = useAppSelector(selectCount);
    const {isLoggedIn} = useSelector((state: RootState) => state.auth);

    return (
        <Navbar collapseOnSelect expand="md" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand>
                    <Link to="/" className="navbar-brand">
                        <Image className="d-inline-block align-top" src={process.env.PUBLIC_URL + "/img/logo.jpg"}
                               alt="TPR logo" roundedCircle width={48} height={48}/>
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <NavItem to="/" label="Home"/>
                        <NavItem to="/gallery" label="Gallery"/>
                        <NavItem to="/contact" label="Contact"/>
                    </Nav>
                    <div className="justify-content-end d-none d-md-block">
                        {isLoggedIn &&
                            <Link to="/basket" className="btn btn-sm btn-primary navbar-btn text-decoration-none">
                                <FontAwesomeIcon icon={faCartShopping}/>
                                <span> Cart {count > 0 && "(" + count + ")"}</span>
                            </Link>}
                        <SocialLinks iconSize={25}/>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

const NavItem = ({to, label}: { to: string, label: string }) => (
    <li className="nav-item">
        <NavLink to={to} className="nav-link text-decoration-none text-uppercase p-4 h-3 fw-bold">{label}</NavLink>
    </li>
);

export default NavBar;
