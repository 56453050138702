import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button, Form, Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {useAppDispatch} from "../app/hooks";
import {decrement} from "../features/counter/counterSlice";

const Basket = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    let subTotal = 0;
    const [validated, ] = useState(false);
    const [order, ] = useState(JSON.parse(localStorage.getItem("basketOrder")));
    const [items, setItems] = useState(JSON.parse(localStorage.getItem("basketItems")));

    const changeQuantity = (id, e) => {
        let newItems = items.map(item => (
            item.id === id ? {...item, quantity: e.target.valueAsNumber} : item
        ))
        setItems(newItems);
    };

    const removeItem = (id) => {
        let newItems = items.filter(i => i.id !== id);
        dispatch(decrement());
        setItems(newItems);
    };

    const renderTableBody = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        const result = items.map((item, index) => {
            subTotal += item.quantity * item.price;
            return (
                <tr key={index}>
                    <td>
                        <Link to={"/detail/" + item.id}>
                            <Image
                                src={process.env.PUBLIC_URL + '/img' + item.category.folder + '/' + item.id + '/' + item.id + '.webp'}
                                thumbnail fluid/>
                        </Link>
                    </td>
                    <td>
                        <Link to={"/detail/" + item.id} className={'text-left'}>
                            {item.title}
                        </Link>
                    </td>
                    <td>
                        <Form.Group controlId="quantity">
                            <Form.Control type="number" defaultValue={item.quantity} min={1} max={item.available}
                                          onChange={(e) => changeQuantity(item.id, e)} required
                                          style={{"width": "80%"}} isInvalid={item.quantity > item.available}/>
                            <small className="text-left">In Stock: {item.available}</small>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid quantity.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/*<input type="number" defaultValue={item.quantity} min={1} max={item.available} style={{"width": "80%"}}*/}
                        {/*       className="form-control"*/}
                        {/*       onChange={(e) => this.changeQuantity(index, e)}/>*/}
                    </td>
                    <td>{"$" + item.price}</td>
                    <td>{"$" + item.price * item.quantity}</td>
                    <td>
                        <Button variant="link" onClick={() => removeItem(item.id)}>
                            <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                    </td>
                </tr>
            );
        })
        localStorage.setItem("basketOrder", JSON.stringify({...order, subTotal: subTotal}));
        return result;
    }

    const handleClickNext = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            navigate("/checkout");
        }
    }

    // useEffect(() => {
    //     localStorage.setItem("basketItems", JSON.stringify(items));
    //     window.dispatchEvent(new Event('storage'))
    // }, [items]);

    return (
        <div id="content">
            <div className="container">
                <div className="row">
                    {/*<div className="col-lg-12">*/}
                    {/*    <nav aria-label="breadcrumb">*/}
                    {/*        <ol className="breadcrumb">*/}
                    {/*            <li className="breadcrumb-item">*/}
                    {/*                <a href="#">Home</a>*/}
                    {/*            </li>*/}
                    {/*            <li aria-current="page" className="breadcrumb-item active">*/}
                    {/*                Ordering cart*/}
                    {/*            </li>*/}
                    {/*        </ol>*/}
                    {/*    </nav>*/}
                    {/*</div>*/}
                    <div id="basket" className="col-md-12">
                        <div className="box">
                            <h1>Ordering cart</h1>
                            <p className="text-muted">You currently have {items.length} item(s) in your
                                cart.</p>
                            <Form noValidate validated={validated} onSubmit={handleClickNext}>
                                {items.length > 0 &&
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th colSpan="2">Product</th>
                                                <th>Quantity</th>
                                                <th>Unit price</th>
                                                {/*<th>Discount</th>*/}
                                                <th>Total(u)</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {renderTableBody()}
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <th colSpan="4"></th>
                                                <th colSpan="2">{"Sub Total: $" + subTotal}</th>
                                                <th></th>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                }
                                <div className="box-footer d-flex justify-content-between flex-column flex-lg-row">
                                    <div className="left">
                                        <button onClick={() => {
                                            navigate('/gallery')
                                        }} className="btn btn-outline-secondary">
                                            <FontAwesomeIcon icon={faChevronLeft} /> Keep looking
                                        </button>
                                    </div>
                                    <div className="right">
                                        {/*<button className="btn btn-outline-secondary">*/}
                                        {/*    <i className="fa fa-refresh"></i> Update cart*/}
                                        {/*</button>*/}
                                        {items.length > 0 &&
                                            <Button type="submit" variant="primary">
                                                Proceed to checkout <FontAwesomeIcon icon={faChevronRight} />
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </Form>
                        </div>
                        {/*<OtherList/>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Basket;
