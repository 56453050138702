import http from "./http-common";

const headers = () => {
    return {
        headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken')
        }
    }
}

export default class OrderService {
    getAll() {
        return http.get("order");
    }

    get(id) {
        return http.get(`order/${id}`);
    }

    create(data) {
        return http.post("order", data, headers());
    }

    update(id, data) {
        return http.put(`order/${id}`, data, headers());
    }

    delete(id) {
        return http.delete(`order/${id}`, headers());
    }
}