import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { Image, Spinner } from "react-bootstrap";
import ItemService from "../services/item.service";
import Skeleton from "react-loading-skeleton";

const HomeHotSlider = (props) => {
    const slides = props.slides || 5;
    const itemService = new ItemService();
    const [items, setItems] = useState([]);
    const responsive = {
        0: { items: 1 },
        450: { items: 2 },
        600: { items: 3 },
        1000: { items: 5 },
    };

    useEffect(() => {
        itemService.getTopView()
            .then(response => setItems(response.data))
            .catch(error => console.error("Error fetching data:", error));
    }, []);

    const renderItems = () => (
        <OwlCarousel
            className="product-slider owl-carousel owl-theme slider-items"
            items={slides}
            autoplay={true}
            margin={8}
            loop
            responsive={responsive}
        >
            {items.map(item => (
                <div className="item" key={item.id}>
                    <div className="img-thumbnail product">
                        <Link to={"/detail/" + item.id}>
                            <Image
                                src={`${process.env.PUBLIC_URL}/img${item.category.folder}/${item.id}/${item.id}_1.webp`}
                                fluid
                                rounded
                            />
                        </Link>
                        <div className="text">
                            <h3><Link to={"/detail/" + item.id}>{item.title}</Link></h3>
                            <p className="price">
                                <del>{item.promotion && `$${item.price}`}</del>
                                {" $" + (item.promotion ? item.promotion : item.price)}
                            </p>
                        </div>
                        {item.status && (
                            <div className="ribbon sale">
                                <div className="theribbon rounded-right">NEW</div>
                                <div className="ribbon-background"></div>
                            </div>
                        )}
                        {item.promotion && (
                            <div className="ribbon new">
                                <div className="theribbon rounded-right">
                                    -${parseInt(item.price) - parseInt(item.promotion)}
                                </div>
                                <div className="ribbon-background"></div>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </OwlCarousel>
    );

    return (
        <>
            <div className="py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="text-primary text-uppercase">Hot this week</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div id="hot" className="container">
                {items.length > 0 ? renderItems() :
                    <div className="row">
                        <OwlCarousel
                            className="product-slider owl-carousel owl-theme slider-items"
                            autoplay={true}
                            margin={8}
                            loop
                            responsive={responsive}
                        >
                            <div className="item">
                                <div className="img-thumbnail product">
                                    <Skeleton height={100} />
                                    <div className="text">
                                        <h3>
                                            <Skeleton width={120} />
                                            <Skeleton width={120} />
                                        </h3>
                                        <p className="price"><Skeleton width={40} /></p>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                }
            </div>
        </>
    );
};

export default HomeHotSlider;