import React, {useState} from "react";
import AppService from "../services/app.service";
import {Button, Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignIn} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {login} from "../slices/auth.slide";

const Login = () => {
    const appService = new AppService()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleClickLogin = (event) => {
        event.preventDefault();
        // appService.getToken(username, password).then((response) => {
        //     localStorage.setItem("accessToken", response.data["accessToken"]);
        //     navigate('/admin')
        // })
        dispatch(login({username, password})).unwrap().then(() => {
            navigate('/admin');
        });
    }

    return (
        <div className="container">
            <div id="infoToast" className="toast" role="alert" aria-live="assertive" aria-atomic="true"
                 data-bs-animation="false" data-bs-delay="2000">
                <div className="toast-body bg-info text-light d-flex justify-content-between">
                    <p className="mb-0">As experts in lifting, moving, and nuts-and-bolting, the robotic population is
                        an ideal match for industry and repetitive tasks.</p>
                    <button type="button" className="btn-close btn-close-white ms-3" data-bs-dismiss="toast"
                            aria-label="Close"></button>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="box">
                        <h1>Login</h1>
                        <Form onSubmit={handleClickLogin}>
                            <Row>
                                <Form.Group controlId="username" as={Col} md="6">
                                    <Form.Label>Username*</Form.Label>
                                    <Form.Control type="text" value={username}
                                                  onChange={(e) => setUsername(e.target.value)} placeholder="Username"
                                                  required/>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a Username.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group controlId="password" as={Col} md="6">
                                    <Form.Label>Password*</Form.Label>
                                    <Form.Control type="text" value={password}
                                                  onChange={(e) => setPassword(e.target.value)} placeholder="Password"
                                                  required/>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a Password.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Button type="submit" variant="primary">
                                SignIn<FontAwesomeIcon icon={faSignIn}/>
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
