import React from 'react';

export default function CopyRight() {
    return (
        <footer className="bg-dark py-2 text-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mb-2 mb-lg-0">
                        <p className="text-center text-lg-left m-0">©2021-2024 Tropical Party Rental, All rights reserved.</p>
                    </div>
                    <div className="col-lg-6">
                        <p className="text-center text-lg-right m-0">
                            Template design by SoftSonic with <a href="https://bootstrapious.com/" className="text-white">Bootstrapious</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};
