import React from 'react';
import { Link } from 'react-router-dom';
import SocialLinks from './socialLinks';
import CopyRight from "./copyright";

const Footer = () => (
    <div className={" mt-auto"}>
        <footer id="footer" className="bg-light py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <h4 className="mb-3 text-primary fw-bold">Pages</h4>
                        <ul className="list-unstyled">
                            <li>
                                <Link to="/contact" className="text-decoration-none text-dark">Contact</Link>
                            </li>
                            <li>
                                <Link to="/gallery" className="text-decoration-none text-dark">Gallery</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="mb-3 text-primary fw-bold">Top categories</h4>
                        <ul className="list-unstyled">
                            <li>Water Slides</li>
                            <li>Bounce House</li>
                            <li>Tables & Chairs</li>
                            <li>Tent</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h4 className="mb-3 text-primary fw-bold">Where to find us</h4>
                        <p>
                            Homestead
                            <br/>
                            <strong>Florida</strong>
                        </p>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <ul className="list-unstyled">
                            <li><a href="tel:+1786-260-4808" className="text-decoration-none text-dark">+1 (786) 260
                                4808</a></li>
                            <li><a href="tel:+1305-607-7291" className="text-decoration-none text-dark">+1 (305) 607
                                7291</a></li>
                        </ul>
                        <p className="social text-center">
                            <SocialLinks/>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
        <CopyRight/>
    </div>
);

export default Footer;
