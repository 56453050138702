import http from "./http-common";

const headers = () => {
    return {
        headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken')
        }
    }
}

export default class EventService {

    getAll() {
        return http.get("event");
    }

    get(id) {
        return http.get(`event/${id}`);
    }

    create(data) {
        return http.post("event", data, headers());
    }

    update(id, data) {
        return http.put(`event/${id}`, data, headers());
    }

    delete(id) {
        return http.delete(`event/${id}`, headers());
    }

}