import React from 'react';
import TopBar from "./topbar";
import NavBar from "./navbar";

const Header = () => {
    return (
        <header className="header mb-5 py-5 py-md-3">
            <TopBar/>
            <NavBar/>
        </header>
    );
};


export default Header;
