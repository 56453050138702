import React, {useState} from "react";
import {Button, Form, Row} from "react-bootstrap";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CheckoutPayment = (props) => {
    const order = JSON.parse(localStorage.getItem("basketOrder"));
    const [obj,] = useState({
        ...order,
        paymentMethod: order.paymentMethod === undefined ? true : order.paymentMethod,
    })

    const handleClickNext = () => {
        localStorage.setItem("basketOrder", JSON.stringify(obj));
        props.changeUrl("review");
    };

    const handleClickPrevious = () => {
        props.changeUrl("delivery");
    };

    return (
        <div className="content py-3">
            <Row>
                <div className="col-md-6">
                    <div className="box payment-method">
                        <h4>Cash or Zelle on delivery</h4>
                        <p>You pay when you get it.</p>
                        <div className="box-footer text-center">
                            <Form.Check defaultChecked={true} value={obj.paymentMethod} name={'payment'} type={'radio'} id={'cash'}></Form.Check>
                        </div>
                    </div>
                </div>
                {/*<div className="col-md-6">*/}
                {/*    <div className="box payment-method">*/}
                {/*        <h4>Zelle on delivery</h4>*/}
                {/*        <p>You pay when you get it.</p>*/}
                {/*        <div className="box-footer text-center">*/}
                {/*            <FormCheck name={'payment'} type={'radio'} id={'zelle'}></FormCheck>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </Row>
            <div className="box-footer d-flex justify-content-between">
                <button onClick={handleClickPrevious} className="btn btn-outline-secondary">
                    <FontAwesomeIcon icon={faChevronLeft} />Back to Shipping Method
                </button>
                <Button onClick={handleClickNext} className="btn btn-primary">
                    Continue to Order Review<FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>
        </div>
    );
}

export default CheckoutPayment;
