import React, {useCallback} from "react";
import CheckoutAddress from "../components/checkoutAddress";
import CheckoutDelivery from "../components/checkoutDelivery";
import CheckoutPayment from "../components/checkoutPayment";
import CheckoutReview from "../components/checkoutReview";
import CheckoutOrderSummaryBar from "../components/checkoutOrderSummaryBar";
import {useNavigate} from "react-router-dom";
import {faEye, faLocationDot, faMoneyCheckDollar, faTruck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CheckOut = () => {
    const navigate = useNavigate()
    const subTotal = JSON.parse(localStorage.getItem("basketOrder")).subTotal;
    const [url, setUrl] = React.useState("address");
    const [shipping, setShipping] = React.useState(JSON.parse(localStorage.getItem("basketOrder")).shipping);

    const handleChangeUrl = useCallback((url) => {
        setUrl(url)
    }, [])

    const handleChangeShipping = useCallback((shipping) => {
        setShipping(shipping)
    }, [])

    const renderContent = () => {
        switch (url) {
            case "delivery":
                return <CheckoutDelivery changeUrl={handleChangeUrl}/>;
            case "payment":
                return <CheckoutPayment changeUrl={handleChangeUrl}/>;
            case "review":
                return <CheckoutReview changeUrl={handleChangeUrl}/>;
            default:
                return <CheckoutAddress changeUrl={handleChangeUrl} setShipping={handleChangeShipping}
                                        subTotal={subTotal}/>;
        }
    }

    const renderCheckOut = () => {
        if (subTotal === undefined) {
            navigate('/basket');
        }
        return <div id="content">
            <div className="container">
                <div className="row">
                    {/*<div className="col-lg-12">*/}
                    {/*    <nav aria-label="breadcrumb">*/}
                    {/*        <ol className="breadcrumb">*/}
                    {/*            <li className="breadcrumb-item">*/}
                    {/*                <Link to={'/'}>Home</Link>*/}
                    {/*            </li>*/}
                    {/*            <li aria-current="page" className="breadcrumb-item active">*/}
                    {/*                Checkout*/}
                    {/*            </li>*/}
                    {/*        </ol>*/}
                    {/*    </nav>*/}
                    {/*</div>*/}
                    <div id="checkout" className="col-lg-9">
                        <div className="box">
                            <h1>Address</h1>
                            <div className="nav flex-column flex-md-row nav-pills text-center">
                                    <span
                                        className={'nav-link flex-sm-fill text-sm-center' + (url === 'address' ? ' active' : ' disabled')}>
                                      <FontAwesomeIcon icon={faLocationDot} /><br/>Address
                                    </span>
                                <span
                                    className={'nav-link flex-sm-fill text-sm-center' + (url === 'delivery' ? ' active' : ' disabled')}>
                                      <FontAwesomeIcon icon={faTruck} /><br/>Delivery
                                    </span>
                                <span
                                    className={'nav-link flex-sm-fill text-sm-center' + (url === 'payment' ? ' active' : ' disabled')}>
                                      <FontAwesomeIcon icon={faMoneyCheckDollar} /><br/>Payment Method
                                    </span>
                                <span
                                    className={'nav-link flex-sm-fill text-sm-center' + (url === 'review' ? ' active' : ' disabled')}>
                                      <FontAwesomeIcon icon={faEye} /><br/>Order Review
                                    </span>
                            </div>
                            {renderContent()}
                        </div>
                    </div>
                    <CheckoutOrderSummaryBar shipping={shipping} subTotal={subTotal}/>
                </div>
            </div>
        </div>;
    };

    return renderCheckOut();
}

export default CheckOut;
