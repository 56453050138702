import React from "react";
import {Link} from "react-router-dom";
import {Image} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";

const NotFound = () => {
    return (
        <div id="content">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div id="error-page" className="row">
                            <div className="col-md-6 mx-auto">
                                <div className="box text-center py-5">
                                    <Image src={"img/logo.jpg"} width={50} height={50} alt="Tropical Party Rental"/>
                                    <h3>We are sorry - this page is not here anymore</h3>
                                    <h4 className="text-muted">Error 404 - Page not found</h4>
                                    <p className="text-center">
                                        To continue please use the <strong>Menu</strong> above.
                                    </p>
                                    <p className="buttons">
                                        <Link to="/" className="btn btn-primary">
                                            <FontAwesomeIcon icon={faHouse}/> Go to Homepage
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFound;
